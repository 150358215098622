import { enhancedApi, Location, ServiceType } from '../../codegen/location';
import { appConfiguration } from '../../configuration/appConfiguration';

const DEFAULT_PAGE_SIZE = appConfiguration.api.location.pageSize;

type ListAllLocationsResponse = { items: Location[] };

const injectApiEndpoints = enhancedApi.injectEndpoints({
  endpoints: (build) => ({
    getAllLocations: build.query<
      ListAllLocationsResponse,
      { serviceTypes?: ServiceType[] }
    >({
      queryFn: async (queryArg, _queryApi, _extraOptions, fetchWithBQ) => {
        const serviceTypes = queryArg.serviceTypes;

        // Do not request locations if no service type is selected
        if (!serviceTypes || serviceTypes.length === 0) {
          return { data: { items: [] } };
        }

        const aggregatedResponse: ListAllLocationsResponse = {
          items: [] as Location[],
        };
        let totalPages = 1;
        const url = '/locations';

        for (let currentPage = 0; currentPage < totalPages; currentPage++) {
          const pageResult = await fetchWithBQ({
            url,
            params: {
              pageSize: DEFAULT_PAGE_SIZE,
              pageNumber: currentPage,
              serviceType: serviceTypes,
            },
          });

          if (pageResult.error) {
            return { error: pageResult.error };
          }

          const pageData = pageResult.data;
          aggregatedResponse.items.push(...pageData.items);

          if (currentPage === 0) {
            totalPages = pageData.totalPages;
          }
        }

        return { data: aggregatedResponse };
      },
    }),
  }),
});

export const { useGetAllLocationsQuery, useGetParkingLocationQuery } =
  injectApiEndpoints;
