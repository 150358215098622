export interface StorageUtil {
  discardAccessToken: Function;
  getAccessToken: Function;
  hasAccessToken: Function;
  saveAccessToken: Function;
}

export const configureStorage = (): StorageUtil => {
  let storedAccessToken: string | null = null;
  return {
    discardAccessToken: () => (storedAccessToken = null),
    getAccessToken: () => storedAccessToken,
    hasAccessToken: () => Boolean(storedAccessToken),
    saveAccessToken: (token: string) => (storedAccessToken = token),
  };
};

export const extractAccessTokenFromWindowLocation = (window?: Window) => {
  if (
    !window ||
    !window.location ||
    !window.location.href ||
    typeof window.location.href !== 'string'
  ) {
    return;
  }

  let token;

  const replacer = (substring: string, arg: string): string => {
    token = arg;
    return substring;
  };

  window.location.href.replace(/access_token=([^&]+)/u, replacer);

  return token;
};

export const configureAccessToken = (
  window: Window | undefined,
  storage: StorageUtil,
) => {
  const urlToken = extractAccessTokenFromWindowLocation(window);

  if (urlToken) {
    storage.saveAccessToken(urlToken);
  }

  return storage;
};

// The window reference error occurs because this code runs on both server (Playwright) and client
// We need to check if window is defined before using it
export const accessToken = configureAccessToken(
  typeof window !== 'undefined' ? window : undefined,
  configureStorage(),
);
