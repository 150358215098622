import React from 'react';
import { CustomFormattedMessage, useCustomIntl } from '../../../i18n/i18n';
import { FormField } from '../../../components/form/FormField';
import { Controller, FieldErrors } from 'react-hook-form';
import { DatePicker } from '../../../components/form/DatePicker';
import { TimePicker } from '../../../components/form/TimePicker';
import { DateUtils } from '../../../components/utils/DateUtils';
import moment, { Moment } from 'moment-timezone';
import { Form } from './BookingDetailsForm';
import { TimeConstraints } from 'react-datetime';
import { UseFormReturn } from 'react-hook-form/dist/types';
import classNames from 'classnames';

const TIME_PATTERN = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
const DATE_PATTERN = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
const RETAIN_OLD_ERROR = 'RETAIN_OLD_ERROR';

// min=1 and max=60 are required to make sure the 15-min steps are correctly applied
// With min=0 (which would be the more natural choice) it subtracts 14 min when decreasing the time to the next step
const TIME_CONSTRAINT: TimeConstraints = {
  minutes: { min: 1, max: 60, step: 15 },
};

type SchedulerDetailsProps = {
  form: UseFormReturn<Form>;
  timezone: string;
  errors: FieldErrors<Form>;
};

export const SchedulerDetails: React.FC<SchedulerDetailsProps> = ({
  form,
  timezone,
  errors,
}) => {
  const intl = useCustomIntl();

  const { control, trigger, setValue, clearErrors, setError, getValues } = form;

  const showErrorForSchedulerSection = (errorMessage: string) => {
    setError('scheduler.arrivalDate', { type: 'custom' });
    setError('scheduler.arrivalTime', { type: 'custom' });
    setError('scheduler.departureDate', { type: 'custom' });
    setError('scheduler.departureTime', { type: 'custom' });
    setError('scheduler', { type: 'custom', message: errorMessage });
  };

  const hasErrorsInScheduleFields = (
    arrivalDate?: moment.Moment,
    arrivalTime?: moment.Moment,
    departureDate?: moment.Moment,
    departureTime?: moment.Moment,
  ) => {
    let hasErrors = false;
    if (arrivalDate && !moment.isMoment(arrivalDate)) {
      const datePatternErrorMessage = intl.formatMessage({
        id: 'bookingDetailsPage.errorMessage.datePattern',
      });
      setError('scheduler.arrivalDate', {
        type: 'custom',
        message: datePatternErrorMessage,
      });
      hasErrors = true;
    }

    if (arrivalTime && !moment.isMoment(arrivalTime)) {
      const datePatternErrorMessage = intl.formatMessage({
        id: 'bookingDetailsPage.errorMessage.timePattern',
      });
      setError('scheduler.arrivalTime', {
        type: 'custom',
        message: datePatternErrorMessage,
      });
      hasErrors = true;
    }

    if (departureDate && !moment.isMoment(departureDate)) {
      const datePatternErrorMessage = intl.formatMessage({
        id: 'bookingDetailsPage.errorMessage.datePattern',
      });
      setError('scheduler.departureDate', {
        type: 'custom',
        message: datePatternErrorMessage,
      });
      hasErrors = true;
    }

    if (departureTime && !moment.isMoment(departureTime)) {
      const datePatternErrorMessage = intl.formatMessage({
        id: 'bookingDetailsPage.errorMessage.timePattern',
      });
      setError('scheduler.departureTime', {
        type: 'custom',
        message: datePatternErrorMessage,
      });
      hasErrors = true;
    }

    return hasErrors;
  };

  const checkForErrors = (): string | undefined => {
    const { arrivalDate, arrivalTime, departureDate, departureTime } =
      getValues('scheduler');

    const datesInTheFutureErrorMessage = intl.formatMessage({
      id: 'bookingDetailsPage.errorMessage.datesInTheFuture',
    });
    const arrivalBeforeDepartureErrorMessage = intl.formatMessage({
      id: 'bookingDetailsPage.errorMessage.arrivalBeforeDeparture',
    });

    if (
      hasErrorsInScheduleFields(
        arrivalDate,
        arrivalTime,
        departureDate,
        departureTime,
      )
    ) {
      return RETAIN_OLD_ERROR;
    }

    if (!arrivalDate || !arrivalTime || !departureDate || !departureTime) {
      return;
    }

    const now = moment.tz(timezone);
    const arrivalDateTime = DateUtils.combineDateAndTime(
      arrivalDate,
      arrivalTime,
      timezone,
    );
    const departureDateTime = DateUtils.combineDateAndTime(
      departureDate,
      departureTime,
      timezone,
    );
    const isArrivalBeforeDeparture = arrivalDateTime < departureDateTime;

    const areDatesInTheFuture =
      arrivalDateTime.isAfter(now) && departureDateTime.isAfter(now);

    if (!isArrivalBeforeDeparture) {
      return arrivalBeforeDepartureErrorMessage;
    }
    if (!areDatesInTheFuture) {
      return datesInTheFutureErrorMessage;
    }

    return undefined;
  };

  const validateDateField = (value?: Moment): string | boolean => {
    if (!value) {
      return intl.formatMessage({
        id: 'common.errorMessage.required',
      });
    }

    if (
      !moment.isMoment(value) ||
      !DATE_PATTERN.test(value.format('DD/MM/YYYY'))
    ) {
      return intl.formatMessage({
        id: 'bookingDetailsPage.errorMessage.datePattern',
      });
    }

    return validateScheduler();
  };

  const validateTimeField = (value?: Moment): string | boolean => {
    if (!value) {
      return intl.formatMessage({
        id: 'common.errorMessage.required',
      });
    }

    if (!moment.isMoment(value) || !TIME_PATTERN.test(value.format('HH:mm'))) {
      return intl.formatMessage({
        id: 'bookingDetailsPage.errorMessage.timePattern',
      });
    }

    const errorTimeIn15MinSteps = validateTime15MinSteps(value);
    if (errorTimeIn15MinSteps) {
      return errorTimeIn15MinSteps;
    }

    return validateScheduler();
  };

  const validateScheduler = (): boolean => {
    const errorMessage = checkForErrors();

    if (!errorMessage) {
      clearErrors('scheduler');
      return true;
    }

    if (errorMessage !== RETAIN_OLD_ERROR) {
      showErrorForSchedulerSection(errorMessage);
      return false;
    }

    return true;
  };

  const validateTime15MinSteps = (value?: Moment): string | undefined => {
    const errorMessage = intl.formatMessage({
      id: 'bookingDetailsPage.errorMessage.time15MinutesStepPattern',
    });

    if (!value) {
      return errorMessage;
    }

    if (moment.isMoment(value) && ![0, 15, 30, 45].includes(value.minutes())) {
      return errorMessage;
    }
  };

  const isDateAfterYesterday = (date: Moment): boolean => {
    return date.isAfter(moment.tz(timezone).subtract(1, 'd'));
  };

  const prefillArrivalTime = () => {
    const currentArrivalTime = getValues('scheduler.arrivalTime');
    if (!currentArrivalTime) {
      setValue(
        'scheduler.arrivalTime',
        DateUtils.roundTimeToNextQuarter(moment.tz(timezone)).seconds(0),
      );
    }
  };

  const prefillDepartureTime = () => {
    const currentArrivalTime = getValues('scheduler.arrivalTime');
    if (!errors.scheduler && moment.isMoment(currentArrivalTime)) {
      setValue(
        'scheduler.departureTime',
        currentArrivalTime.clone().add('15', 'minutes'),
      );
    }
  };

  return (
    <>
      <label htmlFor="arrivalDate">
        <CustomFormattedMessage id={'common.label.arrival'} />
      </label>
      <div className="display-flex gap-15">
        <div className="flex-1-1-0">
          <FormField
            error={errors.scheduler?.arrivalDate}
            data-testid={'booking-details-form-arrival-date'}
          >
            <Controller
              name={'scheduler.arrivalDate'}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  value={value}
                  isValidDate={(currentDate) =>
                    isDateAfterYesterday(currentDate)
                  }
                  onChange={(date) => {
                    onChange(date);
                    prefillArrivalTime();
                    trigger('scheduler.arrivalDate');
                  }}
                />
              )}
              control={control}
              rules={{
                validate: validateDateField,
              }}
            />
          </FormField>
        </div>
        <div className="flex-1-1-0 z-index-5">
          <FormField
            error={errors.scheduler?.arrivalTime}
            data-testid={'booking-details-form-arrival-time'}
          >
            <Controller
              name={'scheduler.arrivalTime'}
              render={({ field: { onChange, value } }) => (
                <TimePicker
                  value={value}
                  onChange={(date) => {
                    onChange(date);
                    trigger('scheduler.arrivalTime');
                  }}
                  timeConstraints={TIME_CONSTRAINT}
                />
              )}
              control={control}
              rules={{
                validate: validateTimeField,
              }}
            />
          </FormField>
        </div>
      </div>
      <label htmlFor="departureDate">
        <CustomFormattedMessage id={'common.label.departure'} />
      </label>
      <div className="display-flex gap-15">
        <div className="flex-1-1-0">
          <FormField
            error={errors.scheduler?.departureDate}
            data-testid={'booking-details-form-departure-date'}
          >
            <Controller
              name={'scheduler.departureDate'}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  value={value}
                  isValidDate={(currentDate) => {
                    return isDateAfterYesterday(currentDate);
                  }}
                  onChange={(date) => {
                    onChange(date);
                    prefillDepartureTime();
                    trigger('scheduler.departureDate');
                  }}
                />
              )}
              control={control}
              rules={{
                validate: validateDateField,
              }}
            />
          </FormField>
        </div>
        <div className="flex-1-1-0">
          <FormField
            error={errors.scheduler?.departureTime}
            data-testid={'booking-details-form-departure-time'}
          >
            <Controller
              name={'scheduler.departureTime'}
              render={({ field: { onChange, value } }) => (
                <TimePicker
                  value={value}
                  onChange={(date) => {
                    onChange(date);
                    trigger('scheduler.departureTime');
                  }}
                  timeConstraints={TIME_CONSTRAINT}
                />
              )}
              control={control}
              rules={{
                validate: validateTimeField,
              }}
            />
          </FormField>
        </div>
      </div>
      {errors.scheduler?.message && (
        <span
          data-testid={'booking-details-form-scheduler-error-message'}
          className={classNames(
            'text-color-white',
            'bg-danger',
            'rounded',
            'padding-5',
            'margin-top-10',
            'margin-bottom-20',
            'text-size-12',
            'display-grid',
          )}
        >
          {errors.scheduler?.message}
        </span>
      )}
    </>
  );
};
