import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../state/store';
import {
  BookingDetails,
  LocationData,
  ParkingBookingState,
  VehicleData,
} from '../../../data/models';

const initialState: ParkingBookingState = {};

export const parkingBookingSlice = createSlice({
  name: 'parkingBooking',
  initialState,
  reducers: {
    enterBookingFlow: (state, action: PayloadAction<LocationData>) => {
      state = { ...initialState };
      state.locationData = action.payload;
      return state;
    },
    resetBookingState: () => initialState,
    setLocationData: (state, action: PayloadAction<LocationData>) => {
      state.locationData = action.payload;
    },
    setVehicleData: (state, action: PayloadAction<VehicleData>) => {
      state.vehicleData = {
        id: action.payload.id,
        name: action.payload.name,
        licensePlate: action.payload.licensePlate,
      };
    },
    setBookingDetails: (state, action: PayloadAction<BookingDetails>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const {
  enterBookingFlow,
  resetBookingState,
  setVehicleData,
  setLocationData,
  setBookingDetails,
} = parkingBookingSlice.actions;
export const getParkingBookingData = (state: RootState) => state.parkingBooking;
