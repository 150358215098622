import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

import { LocationFilterPage } from '../pages/locationFilter/LocationFilterPage';
import { App } from '../App';
import { LocationDetailsPage } from '../pages/locationDetails/LocationDetailsPage';
import { RedirectPage } from '../pages/RedirectPage';
import { BookingDetailsPage } from '../pages/parkingBooking/bookingDetails/BookingDetailsPage';
import { BookingSummaryPage } from '../pages/parkingBooking/bookingSummary/BookingSummaryPage';
import {
  ENVIRONMENT_CONTROL_ROUTE,
  LOCATION_DETAILS_ROUTE,
  PARKING_BOOKING_DETAILS_ROUTE,
  PARKING_BOOKING_ROUTE,
  PARKING_BOOKING_SUMMARY_ROUTE,
  REDIRECT_ROUTE,
  ROOT_ROUTE,
} from './routes';
import { EnvironmentControlPage } from '../pages/EnvironmentControlPage';

export const appRoutes = createRoutesFromElements(
  <Route
    path={ROOT_ROUTE}
    element={<App />}
  >
    <Route
      path={ROOT_ROUTE}
      element={<LocationFilterPage />}
    />
    <Route
      path={LOCATION_DETAILS_ROUTE}
      element={<LocationDetailsPage />}
    />
    <Route path={PARKING_BOOKING_ROUTE}>
      <Route
        path={PARKING_BOOKING_DETAILS_ROUTE}
        element={<BookingDetailsPage />}
      />
      <Route
        path={PARKING_BOOKING_SUMMARY_ROUTE}
        element={<BookingSummaryPage />}
      />
    </Route>
    <Route
      path={REDIRECT_ROUTE}
      element={<RedirectPage />}
    />
    <Route
      path={`${ENVIRONMENT_CONTROL_ROUTE}/*`}
      element={<EnvironmentControlPage />}
    />
  </Route>,
);

export const routing = createBrowserRouter(appRoutes);
