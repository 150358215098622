const ROUTE_BEFORE_REDIRECT_KEY = 'route_before_redirect';
const REDIRECT_TO_ENV_KEY = 'redirect_to_env';
const TEST_ENV = 'TEST';

const configureRedirectingStorage = (window: Window) => {
  const { localStorage } = window;

  return {
    getRouteBeforeAuthRedirect: () =>
      localStorage.getItem(ROUTE_BEFORE_REDIRECT_KEY),
    saveRouteBeforeAuthRedirect: (route: string) =>
      localStorage.setItem(ROUTE_BEFORE_REDIRECT_KEY, route),
    shouldRedirectToTestEnv: () => {
      return localStorage.getItem(REDIRECT_TO_ENV_KEY) === TEST_ENV;
    },
    setRedirectToTestEnv: () => {
      localStorage.setItem(REDIRECT_TO_ENV_KEY, TEST_ENV);
    },
    resetRedirectToTestEnvFlag: () => {
      localStorage.removeItem(REDIRECT_TO_ENV_KEY);
    },
  };
};

export const redirectingStorage = configureRedirectingStorage(window);
