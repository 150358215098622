import { redirectingStorage } from './redirectingStorage';
import { appConfiguration } from '../configuration/appConfiguration';
import { ENVIRONMENT_CONTROL_ROUTE, REDIRECT_ROUTE } from './routes';

// FIXME JUP-5056 remove console logs
export const redirectToTestEnvIfNeeded = () => {
  // If the widget is loaded in the Livemonitor iFrame, only the URL of the iFrame must change.
  const iFrame = document.getElementById(
    'simplePaySearch',
  ) as HTMLIFrameElement;

  const location = iFrame ? iFrame.contentWindow!.location : window.location;
  const path = location.pathname;

  console.warn(location.href);

  if (shouldRedirectToTestEnv(path)) {
    location.href = appConfiguration.externalUrls.testEnvWidgetUrl + path;
  }
};

const shouldRedirectToTestEnv = (path: string) => {
  console.warn('App config: ' + appConfiguration.redirectToTestEnvironment);
  console.warn(
    'Flag in local storage: ' + redirectingStorage.shouldRedirectToTestEnv(),
  );
  console.warn(path);

  return (
    appConfiguration.redirectToTestEnvironment &&
    redirectingStorage.shouldRedirectToTestEnv() &&
    !path.includes(ENVIRONMENT_CONTROL_ROUTE) &&
    !path.includes(REDIRECT_ROUTE)
  );
};
