import { appConfiguration } from '../configuration/appConfiguration';
import { useWidgetContext, WidgetMode } from './useWidgetContext';
import { resetBookingState } from '../pages/parkingBooking/state/parkingBookingSlice';
import { locationDetailsRoute } from '../routing/routes';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../state/store';
import { ThunkDispatch } from '@reduxjs/toolkit';

export type MarkerData = {
  detailsUrl: string;
  enableContextMenu: boolean;
};

export type MarkerPosition = {
  lat: number;
  lng: number;
};

type MarkerProps = {
  name: string;
  markerOnHover: boolean;
};

export type SimplePayPoi = {
  id: string;
  data: MarkerData;
  markerProps: MarkerProps;
  position: MarkerPosition;
};

export type RenderSimplePayPoisEvent = {
  type: 'EVENT_RENDER_SIMPLE_PAY_POIS';
  payload: {
    simplePayPois: SimplePayPoi[];
  };
};

export type SelectSimplePayPoisEvent = {
  type: 'EVENT_MAP_SIMPLE_PAY_POIS_SELECTED';
  payload: {
    ids: string[];
  };
};

export type CenterMapEvent = {
  type: 'EVENT_CENTER_MAP';
  payload: {
    lat: number;
    lng: number;
    zoom: number;
  };
};

export type FleetMonitorEvent =
  | RenderSimplePayPoisEvent
  | SelectSimplePayPoisEvent
  | CenterMapEvent;

const EVENT_PREFIX = 'EVENT';
const EVENT_MAP_SIMPLE_PAY_POIS_SELECTED = 'EVENT_MAP_SIMPLE_PAY_POIS_SELECTED';

const receiveMessage = (
  event: MessageEvent,
  dispatch: ThunkDispatch<any, any, any>,
  widgetMode: WidgetMode,
  navigate: NavigateFunction,
) => {
  const { type = '', payload } = event.data;

  if (!type.startsWith(EVENT_PREFIX) || !dispatch) {
    return;
  }

  switch (type) {
    case EVENT_MAP_SIMPLE_PAY_POIS_SELECTED: {
      if (widgetMode === WidgetMode.DETAILS) {
        dispatch(resetBookingState());
        navigate(locationDetailsRoute(payload.ids[0]));
      }
      return;
    }
  }
};

export const useRegisterEventListener = () => {
  const dispatch = useAppDispatch();
  const { getWidgetMode } = useWidgetContext();
  const widgetMode = getWidgetMode();
  const navigate = useNavigate();

  const listenerCallback = (event: MessageEvent) =>
    receiveMessage(event, dispatch, widgetMode, navigate);
  window.addEventListener('message', listenerCallback, false);
  return () => window.removeEventListener('message', listenerCallback, false);
};

export const useMessaging = () => {
  const sendEvent = (event: FleetMonitorEvent) => {
    window.parent.postMessage(event, appConfiguration.messaging.targetOrigin);
  };

  return {
    sendEvent,
  };
};
