import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ROOT_ROUTE } from '../routing/routes';
import { useWidgetContext, WidgetMode } from '../hooks/useWidgetContext';
import { redirectingStorage } from '../routing/redirectingStorage';

export const RedirectPage = () => {
  const navigate = useNavigate();
  const { getWidgetMode } = useWidgetContext();

  useEffect(() => {
    let route;
    switch (getWidgetMode()) {
      case WidgetMode.SEARCH:
        route = ROOT_ROUTE;
        break;
      case WidgetMode.DETAILS:
      case WidgetMode.NONE:
        const initialRoute = redirectingStorage.getRouteBeforeAuthRedirect();
        route = initialRoute ? initialRoute : ROOT_ROUTE;
        break;
      default:
        route = ROOT_ROUTE;
        break;
    }

    navigate(route, { replace: true });
  }, []);

  return <></>;
};
