import {
  CenterMapEvent,
  RenderSimplePayPoisEvent,
  SelectSimplePayPoisEvent,
  SimplePayPoi,
  useMessaging,
} from './useMessaging';
import { GeoCoordinates } from '../codegen/location';

export const useMapMarkers = () => {
  const { sendEvent } = useMessaging();

  const sendMarkers = (markers: SimplePayPoi[]) => {
    const event: RenderSimplePayPoisEvent = {
      type: 'EVENT_RENDER_SIMPLE_PAY_POIS',
      payload: {
        simplePayPois: markers,
      },
    };

    sendEvent(event);
  };

  const selectMarker = (markerId: string, geoCoordinates: GeoCoordinates) => {
    const selectMarkerEvent: SelectSimplePayPoisEvent = {
      type: 'EVENT_MAP_SIMPLE_PAY_POIS_SELECTED',
      payload: {
        ids: [markerId],
      },
    };
    sendEvent(selectMarkerEvent);

    const centerMapEvent: CenterMapEvent = {
      type: 'EVENT_CENTER_MAP',
      payload: {
        lat: +geoCoordinates.latitude,
        lng: +geoCoordinates.longitude,
        zoom: 15,
      },
    };
    sendEvent(centerMapEvent);
  };

  return { sendMarkers, selectMarker };
};
