import {
  BaseQueryApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../state/store';
import { notifyError } from '../components/notifications';
import qs from 'qs';

const handleError = (error: any) => {
  notifyError(
    'base.body.notificationBanner.restFailureTitle',
    'base.body.notificationBanner.restFailureMessage',
  );
  return { error };
};

export const restBaseQuery: any = (url: string) => {
  try {
    const baseQuery = fetchBaseQuery({
      baseUrl: url,
      paramsSerializer: (params: Record<string, unknown>) =>
        qs.stringify(params, {
          arrayFormat: 'repeat',
          encode: true,
          strictNullHandling: true,
          allowDots: true,
        }),
      prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).token.accessToken;
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
      },
    });

    return async (
      args: string | FetchArgs,
      api: BaseQueryApi,
      extraOptions: {},
    ) => {
      const { error, data } = await baseQuery(args, api, extraOptions);
      if (error) {
        return handleError(error);
      }
      return { data };
    };
  } catch (error: any) {
    return handleError(error);
  }
};
