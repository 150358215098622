export enum WidgetMode {
  SEARCH = 'SEARCH',
  DETAILS = 'DETAILS',
  NONE = 'NONE',
}

export enum WindowName {
  SIMPLE_PAY_SEARCH = 'simplePaySearch',
  SIMPLE_PAY_SEARCH_TEST = 'simplePaySearchTest',
  SIMPLE_PAY_DETAILS = 'simplePayDetails',
}

export const useWidgetContext = () => {
  const getWidgetMode = (): WidgetMode => {
    const widgetContainerName = window.name;

    switch (widgetContainerName) {
      case WindowName.SIMPLE_PAY_SEARCH:
      case WindowName.SIMPLE_PAY_SEARCH_TEST:
        return WidgetMode.SEARCH;
      case WindowName.SIMPLE_PAY_DETAILS:
        return WidgetMode.DETAILS;
      default:
        return WidgetMode.NONE;
    }
  };

  return {
    getWidgetMode,
  };
};
