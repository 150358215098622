import { useLocations } from '../../state/location/useLocations';
import { Location, ServiceType } from '../../codegen/location';
import Tree, { TreeItem } from '@rio-cloud/rio-uikit/Tree';
import { useEffect, useMemo, useState } from 'react';
import { Alert, AlertType } from '../../components/Alert';
import { CustomFormattedMessage, useCustomIntl } from '../../i18n/i18n';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import classNames from 'classnames';
import { appConfiguration } from '../../configuration/appConfiguration';
import { useMapMarkers } from '../../hooks/useMapMarkers';
import { SelectionChangeResponse } from '@rio-cloud/rio-uikit/components/assetTree/Tree';
import { WidgetLoadingSpinner } from '../../components/WidgetLoadingSpinner';

export const LocationFilterPage = () => {
  const { locations, error, refetch, isFetching } = useLocations([
    ServiceType.Parking,
  ]);

  const { sendMarkers, selectMarker } = useMapMarkers();
  const intl = useCustomIntl();

  const [selectedLocation, setSelectedLocation] = useState<
    Location | undefined
  >(undefined);

  const handleSelectLocation = ({ items }: SelectionChangeResponse) => {
    const [locationId] = items;
    const location = (locations || []).find((l) => l.id === locationId);
    setSelectedLocation(location);

    if (location) {
      selectMarker(location.id, location.geoCoordinates);
    }
  };

  const markers = useMemo(() => {
    return (locations || []).map((l) => ({
      id: l.id,
      data: {
        detailsUrl: `${appConfiguration.externalUrls.detailsWidgetUrl}/location?locationId=${l.id}`,
        enableContextMenu: true,
      },
      markerProps: {
        name: l.name,
        markerOnHover: false,
      },
      position: {
        lat: +l.geoCoordinates.latitude,
        lng: +l.geoCoordinates.longitude,
      },
    }));
  }, [locations]);

  useEffect(() => {
    sendMarkers(markers);
  }, [markers]);

  const treeItems: TreeItem[] = useMemo(() => {
    return (locations || []).map((l) => ({
      id: l.id,
      name: l.name,
      type: 'parking',
    }));
  }, [locations]);

  if (error) {
    return (
      <div className={'padding-left-20 padding-right-20'}>
        <Alert
          headline={
            <CustomFormattedMessage
              id={'locationFilter.getLocations.errorMessage.header'}
            />
          }
          body={
            <CustomFormattedMessage
              id={'locationFilter.getLocations.errorMessage.body'}
            />
          }
          footer={
            <>
              <Button
                bsStyle={Button.DANGER}
                className={classNames({ 'btn-loading disabled': isFetching })}
                onClick={() => {
                  refetch();
                }}
              >
                <CustomFormattedMessage id={'common.retry'} />
              </Button>
            </>
          }
          type={AlertType.DANGER}
          className={'margin-top-20'}
        />
      </div>
    );
  }

  if (isFetching) {
    return <WidgetLoadingSpinner />;
  }

  return (
    <Tree
      items={treeItems}
      selectedItems={selectedLocation ? [selectedLocation.id] : undefined}
      onSelectionChange={handleSelectLocation}
      hasMultiselect={false}
      data-testid={'location-filter-tree'}
      searchPlaceholder={intl.formatMessage({
        id: 'locationFilter.searchBar.placeholder',
      })}
    />
  );
};
