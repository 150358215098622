import { configureStore } from '@reduxjs/toolkit';
import { localeSlice } from '../i18n/localeSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { tokenSlice } from './login/tokenSlice';
import { parkingBookingSlice } from '../pages/parkingBooking/state/parkingBookingSlice';
import { vehiclesApiTemplateSlice } from './vehicles/vehiclesApiTemplateSlice';
import { locationApiTemplateSlice } from './location/locationApiTemplateSlice';
import { bookingApiTemplateSlice } from './bookings/bookingApiTemplateSlice';

export const createStore = () => {
  return configureStore({
    reducer: {
      locale: localeSlice.reducer,
      token: tokenSlice.reducer,
      parkingBooking: parkingBookingSlice.reducer,
      [vehiclesApiTemplateSlice.reducerPath]: vehiclesApiTemplateSlice.reducer,
      [bookingApiTemplateSlice.reducerPath]: bookingApiTemplateSlice.reducer,
      [locationApiTemplateSlice.reducerPath]: locationApiTemplateSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        vehiclesApiTemplateSlice.middleware,
        bookingApiTemplateSlice.middleware,
        locationApiTemplateSlice.middleware,
      ),
  });
};

export const store = createStore();

// The window reference error occurs because this code runs on both server (Playwright) and client
// We need to check if window is defined before using it
if (typeof window !== 'undefined') {
  // Make store available globally
  window.__store = store;
}

type Store = typeof store;
export type RootState = ReturnType<Store['getState']>;
export type AppDispatch = Store['dispatch'];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
