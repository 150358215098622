import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { redirectingStorage } from '../routing/redirectingStorage';
import { appConfiguration } from '../configuration/appConfiguration';

const PATH_TESTING = 'test';
const PATH_PROD = 'prod';

export const EnvironmentControlPage = () => {
  const location = useLocation();

  const redirectToLivemonitor = () => {
    window.location.href =
      appConfiguration.externalUrls.liveMonitorUrl +
      '?ft=true&ft_simplePay=true&cpois=true&bpois=true&tree=pois;bookablePois';
  };

  useEffect(() => {
    if (location.pathname.includes(PATH_TESTING)) {
      redirectingStorage.setRedirectToTestEnv();
    }

    if (location.pathname.includes(PATH_PROD)) {
      redirectingStorage.resetRedirectToTestEnvFlag();
    }

    redirectToLivemonitor();
  }, []);

  return <></>;
};
