import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './state/store';
import { login } from './state/login/login';
import { RouterProvider } from 'react-router-dom';
import { routing } from './routing/routing';
import { appConfiguration } from './configuration/appConfiguration';
import { redirectToTestEnvIfNeeded } from './routing/testEnvironmentRedirect';

redirectToTestEnvIfNeeded();

const container = document.getElementById('root')!;
const root = createRoot(container);

const createApp = () => {
  const app = (
    <Provider store={store}>
      <RouterProvider router={routing} />
    </Provider>
  );

  return appConfiguration.strictMode ? (
    <React.StrictMode>{app}</React.StrictMode>
  ) : (
    app
  );
};

login().then((user) => {
  if (!user) {
    return <></>;
  }

  return root.render(createApp());
});
