import React from 'react';
import { IntlProvider } from 'react-intl';
import { DEFAULT_LOCALE, getMessagesForLocale } from './i18n/i18n';
import { getLocale } from './i18n/localeSlice';
import { useAppSelector } from './state/store';
import { Outlet } from 'react-router-dom';
import { useSimplePayBookingState } from './hooks/useSimplePayBookingState';
import { Upselling } from './components/Upselling';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import { appConfiguration } from './configuration/appConfiguration';
import { InfoTestEnvironment } from './components/InfoTestEnvironment';
import { useRegisterEventListener } from './hooks/useMessaging';
import { WidgetLoadingSpinner } from './components/WidgetLoadingSpinner';

export const App: React.FC = () => {
  const { isSimplePayBooked, isLoading } = useSimplePayBookingState();
  useRegisterEventListener();

  const locale = useAppSelector(getLocale);
  const messages = getMessagesForLocale(locale);

  return (
    <IntlProvider
      defaultLocale={DEFAULT_LOCALE}
      locale={locale}
      messages={messages}
    >
      <div className="bg-white">
        {appConfiguration.showTestBanner && <InfoTestEnvironment />}
        {isLoading ? (
          <WidgetLoadingSpinner />
        ) : (
          <div data-testid={'widget-content'}>
            {isSimplePayBooked ? <Outlet /> : <Upselling />}
          </div>
        )}
      </div>
      <NotificationsContainer />
    </IntlProvider>
  );
};
