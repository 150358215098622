import { locationApiTemplateSlice as api } from '../state/location/locationApiTemplateSlice';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listLocations: build.query<ListLocationsApiResponse, ListLocationsApiArg>({
      query: (queryArg) => ({
        url: `/locations`,
        params: {
          pageSize: queryArg.pageSize,
          pageNumber: queryArg.pageNumber,
          serviceType: queryArg.serviceType,
          latitude: queryArg.latitude,
          longitude: queryArg.longitude,
          onlinePaymentSupported: queryArg.onlinePaymentSupported,
        },
      }),
    }),
    getFuelingStation: build.query<
      GetFuelingStationApiResponse,
      GetFuelingStationApiArg
    >({
      query: (queryArg) => ({ url: `/locations/fueling/${queryArg.id}` }),
    }),
    getParkingLocation: build.query<
      GetParkingLocationApiResponse,
      GetParkingLocationApiArg
    >({
      query: (queryArg) => ({ url: `/locations/parking/${queryArg.id}` }),
    }),
    getTankCleaningLocation: build.query<
      GetTankCleaningLocationApiResponse,
      GetTankCleaningLocationApiArg
    >({
      query: (queryArg) => ({ url: `/locations/tank-cleaning/${queryArg.id}` }),
    }),
    getWashingLocation: build.query<
      GetWashingLocationApiResponse,
      GetWashingLocationApiArg
    >({
      query: (queryArg) => ({ url: `/locations/washing/${queryArg.id}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type ListLocationsApiResponse = /** status 200 OK */ LocationList;
export type ListLocationsApiArg = {
  pageSize: number;
  pageNumber: number;
  serviceType?: ServiceType[];
  /** Execute a radius search around a given location. Required if longitude is provided */
  latitude?: Latitude;
  /** Execute a radius search around a given location. Required if latitude is provided */
  longitude?: Longitude;
  /** Filters for locations that support online payment through MAN SimplePay.
    Accepted query string values are 'true' and 'false'. Any other values will cause validation errors.
     */
  onlinePaymentSupported?: boolean;
};
export type GetFuelingStationApiResponse = /** status 200 OK */ FuelingLocation;
export type GetFuelingStationApiArg = {
  /** ID of the location */
  id: string;
};
export type GetParkingLocationApiResponse =
  /** status 200 OK */ ParkingLocation;
export type GetParkingLocationApiArg = {
  /** ID of the location */
  id: string;
};
export type GetTankCleaningLocationApiResponse =
  /** status 200 OK */ TankCleaningLocation;
export type GetTankCleaningLocationApiArg = {
  /** ID of the location */
  id: string;
};
export type GetWashingLocationApiResponse =
  /** status 200 OK */ WashingLocation;
export type GetWashingLocationApiArg = {
  /** ID of the location */
  id: string;
};
export type PageResponse = {
  totalPages: number;
  totalItems: number;
};
export type Latitude = string;
export type Longitude = string;
export type GeoCoordinates = {
  latitude: Latitude;
  longitude: Longitude;
};
export type Country = string;
export type Address = {
  street: string;
  zipCode: string;
  city: string;
  state?: string;
  country: Country;
};
export type ServiceDefinition = {
  serviceType: ServiceType;
  servicePartner: ServicePartner;
  /** Online payment capability of a service */
  onlinePaymentSupported: boolean;
};
export type Location = {
  id: string;
  geoCoordinates: GeoCoordinates;
  address: Address;
  name: string;
  brand?: string;
  digitalServices: ServiceDefinition[];
  /** Time zone identifier as defined tz database uniform naming convention. */
  timezone?: string;
};
export type RadiusSearchResponse = {
  /** Will only be returned in a radius search. Specifies the distance of the location to the given coordinates. */
  distanceInKm?: string;
};
export type LocationListItem = Location & RadiusSearchResponse;
export type LocationList = PageResponse & {
  items: LocationListItem[];
};
export type ErrorResponse = {
  message: string;
  errorCode: ErrorCode;
  fields?: string[];
};
export type MonetaryValue = string;
export type MonetaryAmount = {
  value: MonetaryValue;
  /** Currency in ISO 4217 format. */
  currency: string;
};
export type FuelPrice = {
  product: string;
  price: MonetaryAmount;
};
export type TimeOfDay = string;
export type BusinessHours = {
  weekday: Weekday;
  open?: TimeOfDay;
  close?: TimeOfDay;
}[];
export type FuelingLocation = Location & {
  prices?: FuelPrice[];
  businessHours?: BusinessHours;
};
export type PricePerDuration = {
  /** Duration in hours and minutes [hh:mm] */
  duration: string;
  price: MonetaryAmount;
};
export type ParkingLocation = Location & {
  parkingOptions: ParkingOption[];
  openingHours: {
    entry: BusinessHours;
    exit: BusinessHours;
  };
  prices: PricePerDuration[];
};
export type TankCleaningLocation = Location & {
  cleaningOptions: TankCleaningOption[];
  businessHours: BusinessHours;
};
export type WashingLocation = Location & {
  washingOptions: WashingOption[];
  businessHours: BusinessHours;
};
export enum ServiceType {
  Fueling = 'FUELING',
  Washing = 'WASHING',
  Parking = 'PARKING',
  TankCleaning = 'TANK_CLEANING',
}
export enum ServicePartner {
  Uta = 'UTA',
  MockFuelingPartner = 'MOCK_FUELING_PARTNER',
  Travis = 'TRAVIS',
  MockRoadsidePartner = 'MOCK_ROADSIDE_PARTNER',
}
export enum ErrorCode {
  InvalidPathParameters = 'INVALID_PATH_PARAMETERS',
  InvalidQueryParameters = 'INVALID_QUERY_PARAMETERS',
}
export enum Weekday {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}
export enum ParkingOption {
  CoolingMotorsAllowed = 'COOLING_MOTORS_ALLOWED',
  ElectricCoolingCharging = 'ELECTRIC_COOLING_CHARGING',
  AdrPossible = 'ADR_POSSIBLE',
  LongTimeParking = 'LONG_TIME_PARKING',
  DecouplingTrailers = 'DECOUPLING_TRAILERS',
  Fence = 'FENCE',
  SpreadLighting = 'SPREAD_LIGHTING',
  CameraSecurity = 'CAMERA_SECURITY',
  Guard = 'GUARD',
  Toilet = 'TOILET',
  Shower = 'SHOWER',
  Wifi = 'WIFI',
  Hotel = 'HOTEL',
  Restaurant = 'RESTAURANT',
}
export enum TankCleaningOption {
  Ecd = 'ECD',
  Disinfection = 'DISINFECTION',
  ChemicalCleaning = 'CHEMICAL_CLEANING',
  FeedCleaning = 'FEED_CLEANING',
  FoodCleaning = 'FOOD_CLEANING',
  OtherCleaning = 'OTHER_CLEANING',
}
export enum WashingOption {
  CleaningInside = 'CLEANING_INSIDE',
  CleaningOutside = 'CLEANING_OUTSIDE',
  Disinfection = 'DISINFECTION',
}
export const {
  useListLocationsQuery,
  useGetFuelingStationQuery,
  useGetParkingLocationQuery,
  useGetTankCleaningLocationQuery,
  useGetWashingLocationQuery,
} = injectedRtkApi;
